import { roles } from "../../constants/roles";
import { useAppSelector } from "../../effects/useAppSelector";
import { SubscriptionFeature } from "../../types/billing/SubscriptionFeature";
import { moneyUtils } from "../../utils";
import { ShowFor } from "../access";
import { SubscribeLink } from "../access/BlockedFeatureText";
import { RequiredFeature } from "../access/RequiredFeature";
import { SummaryRow } from "../common/summary-row/SummaryRow";
import { SummaryRowGroup } from "../common/summary-row/SummaryRowGroup";
import { SummaryRowItem } from "../common/summary-row/SummaryRowItem";
import { AllBwicsTab } from "./AllBwicsTab";

interface Props {
    tab: AllBwicsTab;
}

export function AllBwicsSummaryRow({ tab }: Props) {
    const totalBwics = useAppSelector(s => s.allBWICS.totalRecordNumber);
    const summary = useAppSelector(s => s.allBWICS.summary);

    const getVolumeValues = () => {
        const usd = summary?.usdVolume ? moneyUtils.amountToMM(summary.usdVolume, true) : null;
        const eur = summary?.euroVolume ? moneyUtils.amountToMM(summary.euroVolume, true) : null;

        return [usd, eur];
    }

    const getBidValues = () => {
        const dealerBids = summary?.totalDealerBids || null;
        const directBids = summary?.totalDirectBids || null;
        
        return [dealerBids, directBids];
    }

    return (
        <SummaryRow>
            <RequiredFeature
                inline
                feature={SubscriptionFeature.BwicMonitorCanViewSummaryRow}
                text={<><SubscribeLink text="Subscribe to the Pro plan" />&nbsp;to see BWICs stats.</>}
            >
                <SummaryRowGroup groupName="BWICs">
                    <SummaryRowItem label="Count" tooltip="Total number of BWICs." value={totalBwics.toLocaleString()} />
                    <SummaryRowItem label="Volume $/€" tooltip="Total volume of USD/EUR bonds." values={getVolumeValues()} />
                    <SummaryRowItem label="Scheduled" tooltip="Number of scheduled BWICs." value={summary?.scheduled.toLocaleString()} />
                    <SummaryRowItem label="Bidding" tooltip="Number of bidding BWICs." value={summary?.bidding.toLocaleString()} />
                    <SummaryRowItem label="Finished" tooltip="Number of finished BWICs." value={summary?.finished.toLocaleString()} />
                </SummaryRowGroup>
                <div className="vertical-divider" />
                <SummaryRowGroup groupName="Securities">
                    <SummaryRowItem label="Count" tooltip="Total number of bonds." value={summary?.totalPositions.toLocaleString()} />
                    {
                        tab === AllBwicsTab.Sell &&
                        <ShowFor roles={roles.seller()}>
                            <SummaryRowItem label="Bids" tooltip="Bids from broker-dealers/bids from clients." values={getBidValues()}/>
                            <SummaryRowItem label="Traded" tooltip="Total number of traded bonds." value={summary?.totalTraded.toLocaleString()} />
                        </ShowFor>
                    }{
                        tab === AllBwicsTab.Buy &&
                        <ShowFor roles={roles.bd()}>
                            <SummaryRowItem label="Bids" tooltip="Number of bids." value={summary?.totalBids.toLocaleString()} />
                            <SummaryRowItem label="Traded" tooltip="Number of trades." value={summary?.totalTraded.toLocaleString()} />
                        </ShowFor>
                    }
                    <SummaryRowItem label="AAA" tooltip="Number of bonds with AAA rating." value={summary?.aaa.toLocaleString()} />
                    <SummaryRowItem label="AA" tooltip="Number of bonds with AA rating." value={summary?.aa.toLocaleString()} />
                    <SummaryRowItem label="A" tooltip="Number of bonds with A rating." value={summary?.a.toLocaleString()} />
                    <SummaryRowItem label="BBB" tooltip="Number of bonds with BBB rating." value={summary?.bbb.toLocaleString()} />
                    <SummaryRowItem label="BB" tooltip="Number of bonds with BB rating." value={summary?.bb.toLocaleString()} />
                    <SummaryRowItem label="B" tooltip="Number of bonds with B rating." value={summary?.b.toLocaleString()} />
                    <SummaryRowItem label="CCC" tooltip="Number of bonds with CCC rating." value={summary?.ccc.toLocaleString()} />
                    <SummaryRowItem label="D" tooltip="Number of bonds with D rating." value={summary?.d.toLocaleString()} />
                    <SummaryRowItem label="NR" tooltip="Number of bonds with NR rating." value={summary?.nr.toLocaleString()} />
                </SummaryRowGroup>
            </RequiredFeature>
        </SummaryRow>
    )
}